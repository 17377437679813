import {LatLngLiteral} from '../packages/google-maps/services/google-maps-types';
import * as _ from 'lodash';
import {Tariff} from '../admin/tariffs/tariff-list/tariff-list.component';

export interface IRegion {
    ao_guid: string;
    parent_guid: string;
    plain_code: string;
    level: number;
    ao_level: number;
    formal_name: string;
    address: string;
    postal_code: string;
    region_code: string;
    short_name: string;
    final: boolean;
}

export interface GroupPermission {
    id: number;
    name: string;
    title: string;
    description: string;
}

export interface Group {
    id: number;
    name: string;
    title: string;
    description: string;
    total_users: number;
    total_devices: number;
    permissions: GroupPermission[];
}

export class User {
    id: number;
    last_name: string;
    first_name: string;
    middle_name: string;
    email: string;
    password: string;
    notify_email: string;
    phone: string;
    gender: number;
    balance: number;
    tariff: Tariff;
    bonuses: number;
    company_id: number;
    tariff_id: number;
    region_code: string;
    lat: number;
    lng: number;
    zoom: number;
    locale: number;
    groups: Group[];
    device_ids: any;
    region: IRegion;
    last_ip: string;
    last_login: string;
    updated_at: string;
    created_at: string;
    pivot: any;
    moderation_incidents: boolean;

    constructor(data) {
        this.id = data.id;
        this.last_name = data.last_name;
        this.first_name = data.first_name;
        this.middle_name = data.middle_name;
        this.email = data.email;
        this.notify_email = data.notify_email;
        this.phone = data.phone;
        this.gender = data.gender;
        this.balance = data.balance;
        this.bonuses = data.bonuses;
        this.company_id = data.company_id;
        this.tariff_id = data.tariff_id;
        this.tariff = data.tariff;
        this.region_code = data.region_code;
        this.lat = parseFloat(data.lat);
        this.lng = parseFloat(data.lng);
        this.zoom = data.zoom;
        this.locale = data.locale;
        this.groups = data.groups;
        this.device_ids = data.device_ids;
        this.moderation_incidents = data.moderation_incidents;
        this.pivot = data.pivot;
        this.region = data.region;
        this.last_ip = data.last_ip;
        this.last_login = data.last_login;
        this.updated_at = data.updated_at;
        this.created_at = data.created_at;
    }

    /**
     * Проверка групповых прав
     *
     * @param permission_name
     * @returns {boolean}
     */
    hasPermission(permission_name) {
        return _.filter(this.groups, group => _.filter(group.permissions, permission => permission.name.toLowerCase() === permission_name.toLowerCase()).length > 0).length > 0;
    }

    hasGroup(group_name) {
        return _.filter(this.groups, group => group_name.toLowerCase() === group.name.toLowerCase()).length > 0;
    }

    public setLatLng(coordinate: LatLngLiteral) {
        this.lat = coordinate.lat;
        this.lng = coordinate.lng;
    }

    public setZoom(zoom: number) {
        this.zoom = zoom;
    }
}
