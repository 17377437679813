import {BrowserModule} from '@angular/platform-browser';
import {NgModule, LOCALE_ID} from '@angular/core';

import {AppComponent} from './app.component';
import {ScriptLoaderService} from './services/script-loader.service';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {AppRoutingModule} from './app-routing.module';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {TokenInterceptor} from './interceptors/token.interceptor';
import {AuthService} from './services/auth.service';
import {EchoService} from './services/echo.service';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AuthGuard} from './guards/auth.guard';
import {ToAstService} from './services/to-ast.service';
import {ApiService} from './services/api.service';
import {I18nService} from './services/i18n.service';

import { registerLocaleData } from '@angular/common';
import localeRu from '@angular/common/locales/ru';
import localeEn from '@angular/common/locales/en';

registerLocaleData(localeRu);

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        AppRoutingModule,
        NgbModule
    ],
    providers: [
        ScriptLoaderService,
        ApiService,
        AuthService,
        I18nService,
        EchoService,
        ToAstService,
        AuthGuard,
        {provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true},
        {
            provide: LOCALE_ID,
            useFactory: (i18nService: I18nService) => {
                console.log('locale ID', i18nService.localeFull);
                return i18nService.localeFull;
            },
            deps: [I18nService]
        }
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule {
}
