import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpRequest} from '@angular/common/http';
import {Observable, of} from 'rxjs';
import {Device, DevicePermissions, Tag} from '../_classes/device';
import {DevicePosition} from '../_classes/device-position';
import {Group, GroupPermission, IRegion, User} from '../_classes/user';

export interface DefaultResponse {
    status: boolean;
    error: string;
}

@Injectable()
export class ApiService {
    constructor(private httpClient: HttpClient) {
    }

    verifyPhone(phone: string) {
        return this.httpClient.post<any>('/api/verify', {phone: phone});
    }

    createCamera(name: string, url: string, tags: string[], server_id: string, serial_number: string) {
        return this.httpClient.post<any>('/api/camera/create', {name: name, url: url, tags: tags, server_id: server_id, uuid: serial_number});
    }

    getCamera(id: number): Observable<Device> {
        return this.httpClient.get<Device>(`/api/camera/${id}`);
    }

    getCameraRecordMinMax(id: number) {
        return this.httpClient.get<string[]>(`/api/camera/${id}/record/min-max`);
    }

    getCameraEvents(id: number, begin: string, end: string, offset: number, limit: number) {
        return this.httpClient.post<any>(`/api/camera/${id}/events`, {begin: begin, end: end, offset: offset, limit: limit});
    }

    getStatisticsDevicesEvents(begin: string, end: string, offset: number, limit: number) {
        return this.httpClient.post<any>('/api/statistics/devices/events', {
            begin: begin,
            end: end,
            offset: offset,
            limit: limit
        });
    }

    getStatisticsDevicesOffline(offset: number, limit: number) {
        return this.httpClient.post<any>('/api/statistics/devices/offline', {offset: offset, limit: limit});
    }

    getUsersCameraAccess(id: number) {
        return this.httpClient.get<any>(`/api/camera/${id}/access/users`);
    }

    updateCameraAccess(id: number, view_for: string, archive_for: string) {
        return this.httpClient.post<any>(`/api/camera/${id}/access/update`, {view_for: view_for, archive_for: archive_for});
    }

    userAddCameraAccess(id: number, email: string) {
        return this.httpClient.post<any>(`/api/camera/${id}/access/user-add`, {email: email});
    }

    userUpdateCameraAccess(id: number, email: string, permissions: DevicePermissions) {
        return this.httpClient.post<any>(`/api/camera/${id}/access/user-update`, {email: email, permissions: permissions});
    }

    userRemoveCameraAccess(id: number, email: string) {
        return this.httpClient.post<any>(`/api/camera/${id}/access/user-remove`, {email: email});
    }

    updateCameraNotify(id: number, enable_email: boolean, email_address: string) {
        return this.httpClient.post<any>(`/api/camera/${id}/events/notify-update`, {
            enable_email: enable_email,
            email_address: email_address
        });
    }

    updateCameraConfig(id: number, form: any) {
        return this.httpClient.post<any>(`/api/camera/${id}/edit/config-update`, form);
    }

    restartCamera(id: number) {
        return this.httpClient.post<any>(`/api/camera/${id}/edit/restart`, {});
    }

    updateCameraPosition(id: number, position: DevicePosition) {
        return this.httpClient.post<any>(`/api/camera/${id}/edit/position-update`, position.toJson());
    }

    removeCamera(id: string) {
        return this.httpClient.post<any>(`/api/camera/${id}/delete`, {});
    }

    getCameraBySearch(term: string): Observable<Device[]> {
        if (term.length < 2) {
            return of([]);
        }

        return this.httpClient.post<Device[]>('/api/cameras/search', {term: term});
    }

    getAddressBySearch(query: string, region_code: string): Observable<Device[]> {
        if (query.length < 2) {
            return of([]);
        }

        return this.httpClient.post<any>('http://fias.is.city/address/suggestions', {
            query: query,
            region_code: region_code
        });
    }

    getRegions(): Observable<IRegion[]> {
        return this.httpClient.get<IRegion[]>('http://fias.is.city/address/regions');
    }

    loginUser(form) {
        return this.httpClient.post<any>('/api/login', form);
    }

    forgetUser(form) {
        return this.httpClient.post<any>('/api/forget', form);
    }

    tokenUser(form) {
        return this.httpClient.post<any>('/api/token', form);
    }

    registerUser(form) {
        return this.httpClient.post<any>('/api/register', form);
    }

    getCameras(params) {
        return this.httpClient.post<any>('/api/cameras', params);
    }

    getDevices(params) {
        return this.httpClient.post<any>('/api/devices', params);
    }

    getCamerasByMultiStream() {
        return this.httpClient.get<any>('/api/cameras/multi-stream');
    }

    addDeviceMultiStream(camera: Device) {
        return this.httpClient.post<any>('/api/cameras/multi-stream/add', {device_id: camera.id});
    }

    removeDeviceMultiStream(camera: Device) {
        return this.httpClient.post<any>('/api/cameras/multi-stream/remove', {device_id: camera.id});
    }

    userProfilePersonalDetails(last_name: string, first_name: string, middle_name: string, phone: string, notify_email: string) {
        return this.httpClient.post<any>('/api/user/profile', {
            last_name: last_name,
            first_name: first_name,
            middle_name: middle_name,
            phone: phone,
            notify_email: notify_email,
        });
    }

    userLocale(locale: string) {
        return this.httpClient.post<any>('/api/user/locale', {
            locale: locale
        });
    }

    userUpdatePassword(old_password: string, password: string, password_confirmation: string) {
        return this.httpClient.post<any>('/api/user/password-update', {
            old_password: old_password,
            password: password,
            password_confirmation: password_confirmation
        });
    }

    getAdminServers(offset: number, limit: number) {
        return this.httpClient.post<any>('/api/admin/servers', {offset: offset, limit: limit});
    }

    getAdminServer(server_id: number) {
        return this.httpClient.get<any>(`/api/admin/server/${server_id}`);
    }

    createAdminServer(form: any) {
        return this.httpClient.post<any>('/api/admin/servers/create', form);
    }

    updateAdminServer(server_id: number, form: any) {
        return this.httpClient.post<any>(`/api/admin/servers/${server_id}/update`, form);
    }

    deleteAdminServer(server_id: number) {
        return this.httpClient.post<any>(`/api/admin/servers/${server_id}/delete`, {});
    }

    adminServerGetDevices(server_id: number, search: string, offset: number, limit: number) {
        return this.httpClient.post<any>(`/api/admin/server/${server_id}/devices`, {
            search: search,
            offset: offset,
            limit: limit
        });
    }

    adminServerDeviceRemap(server_id: number, device_id: number, new_server_id: number) {
        return this.httpClient.post<any>(`/api/admin/server/${server_id}/device/${device_id}/remap`, {new_server_id: new_server_id});
    }

    adminServerAllDevicesRemap(server_id: number, new_server_id: number) {
        return this.httpClient.post<any>(`/api/admin/server/${server_id}/devices/remap`, {new_server_id: new_server_id});
    }

    getIntercoms(form: any) {
        return this.httpClient.post<any>('/api/intercoms', form);
    }

    createIntercom(form: any) {
        return this.httpClient.post<any>('/api/intercom/create', {name: name, url: form});
    }

    loadAdminSettings() {
        return this.httpClient.get<any>('/api/admin/settings');
    }

    updateAdminSettings(settings: any) {
        return this.httpClient.post<any>('/api/admin/settings/update', {settings: settings});
    }

    getAdminGroups(query: string, offset: number, limit: number) {
        return this.httpClient.post<any>('/api/admin/groups', {query: query, offset: offset, limit: limit});
    }

    adminGroupCreate(form: any) {
        return this.httpClient.post<any>('/api/admin/group/create', {
            name: form.name,
            title: form.title,
            description: form.description
        });
    }

    adminGroupUpdate(group_id: number, form: any) {
        return this.httpClient.post<any>(`/api/admin/group/${group_id}/update`, {
            title: form.title,
            description: form.description
        });
    }

    adminGroupDelete(group_id: number) {
        return this.httpClient.post(`/api/admin/group/${group_id}/delete`, {});
    }

    adminGroup(group_id: number) {
        return this.httpClient.get<Group>(`/api/admin/group/${group_id}`);
    }

    adminGroupPermissions() {
        return this.httpClient.get<GroupPermission[]>('/api/admin/group/permissions');
    }

    adminGroupUsers(group_id: number) {
        return this.httpClient.get<User[]>(`/api/admin/group/${group_id}/users`);
    }

    adminGroupDevices(group_id: number) {
        return this.httpClient.get<Device[]>(`/api/admin/group/${group_id}/devices`);
    }

    adminGroupAddPermission(group_id: number, permission_id: number) {
        return this.httpClient.post<any>(`/api/admin/group/${group_id}/permission/add`, {permission_id: permission_id});
    }

    adminGroupDeletePermission(group_id: number, permission_id: number) {
        return this.httpClient.post<any>(`/api/admin/group/${group_id}/permission/delete`, {permission_id: permission_id});
    }

    adminGroupAddUser(group_id: number, email: string) {
        return this.httpClient.post<any>(`/api/admin/group/${group_id}/user/add`, {email: email});
    }

    adminGroupDeleteUser(group_id: number, email: string) {
        return this.httpClient.post<any>(`/api/admin/group/${group_id}/user/delete`, {email: email});
    }

    adminCameraSearch(term: string): Observable<Device[]> {
        if (term.length < 2) {
            return of([]);
        }

        return this.httpClient.post<Device[]>('/api/admin/cameras/search', {term: term});
    }

    adminGroupAddDevice(group_id: number, device_id: number) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/group/${group_id}/devices/add`, {device_id: device_id});
    }

    adminGroupUpdateDevice(group_id: number, device_id: number, permissions: DevicePermissions) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/group/${group_id}/devices/update`, {
            device_id: device_id,
            permissions: permissions
        });
    }

    adminGroupDeleteDevice(group_id: number, device_id: number) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/group/${group_id}/devices/delete`, {device_id: device_id});
    }

    adminGroupTags(group_id: number) {
        return this.httpClient.get<Tag[]>(`/api/admin/group/${group_id}/tags`);
    }

    adminGroupAddTag(group_id: number, tags: string[]) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/group/${group_id}/tags/add`, {tags: tags});
    }

    adminGroupUpdateTag(group_id: number, tag: string, permissions: DevicePermissions) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/group/${group_id}/tags/update`, {
            tag: tag,
            permissions: permissions
        });
    }

    adminGroupDeleteTag(group_id: number, tag: string) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/group/${group_id}/tags/delete`, {tag: tag});
    }

    adminUsers(query: string, offset: number, limit: number) {
        return this.httpClient.post<any>('/api/admin/users', {query: query, offset: offset, limit: limit});
    }

    adminUserCreate(form: any) {
        return this.httpClient.post<any>('/api/admin/user/create', {
            last_name: form.last_name,
            first_name: form.first_name,
            middle_name: form.middle_name,
            email: form.email,
            password: form.password,
            group_id: form.group_id,
        });
    }

    adminUser(user_id: number) {
        return this.httpClient.get<User>(`/api/admin/user/${user_id}`);
    }

    adminUserUpdate(user_id: number, form: any) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/user/${user_id}/update`, {
            last_name: form.last_name,
            first_name: form.first_name,
            middle_name: form.middle_name,
            password: form.password
        });
    }

    adminUserUpdateTariff(user_id: number, user: any) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/user/${user_id}/update-tariff`, {
            balance: user.balance,
            bonuses: user.bonuses,
            tariff_id: user.tariff_id,
        });
    }

    adminUserDelete(user_id: number) {
        return this.httpClient.post<any>(`/api/admin/user/${user_id}/delete`, {});
    }

    adminUserAddGroup(user_id: number, group_id: number) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/user/${user_id}/group/add`, {
            group_id: group_id
        });
    }

    adminUserDeleteGroup(user_id: number, group_id: number) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/user/${user_id}/group/delete`, {
            group_id: group_id
        });
    }

    adminUserTags(user_id: number) {
        return this.httpClient.get<Tag[]>(`/api/admin/user/${user_id}/tags`);
    }

    adminUserAddTag(user_id: number, tags: string[]) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/user/${user_id}/tags/add`, {tags: tags});
    }

    adminUserUpdateTag(user_id: number, tag: string, permissions: DevicePermissions) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/user/${user_id}/tags/update`, {
            tag: tag,
            permissions: permissions
        });
    }

    adminUserDeleteTag(user_id: number, tag: string) {
        return this.httpClient.post<DefaultResponse>(`/api/admin/user/${user_id}/tags/delete`, {tag: tag});
    }

    adminGetUserSearch(term: any) {
        if (term.length < 2) {
            return of([]);
        }

        return this.httpClient.post<User[]>('/api/admin/user/search', {
            term: term
        });
    }

    getTags() {
        return this.httpClient.get<string[]>('/api/tags');
    }

    getAdminStats(begin: string, end: string) {
        return this.httpClient.post<any>('/api/admin/stats', {begin: begin, end: end});
    }

    getAdminStatsUsers(begin: string, end: string, user_id: number, offset: number, limit: number) {
        return this.httpClient.post<any>('/api/admin/stats/users', {
            begin: begin,
            end: end,
            user_id: user_id,
            offset: offset,
            limit: limit
        });
    }

    getAdminTariffs() {
        return this.httpClient.get<any>('/api/admin/tariffs');
    }

    adminTariffCreate(form: any) {
        return this.httpClient.post<any>('/api/admin/tariff/create', form);
    }

    adminTariffEdit(tariff_id: number, form: any) {
        return this.httpClient.post<any>(`/api/admin/tariff/${tariff_id}/edit`, form);
    }

    adminTariffSetDefault(tariff_id: number) {
        return this.httpClient.post<any>(`/api/admin/tariff/${tariff_id}/set-default`, {});
    }

    adminTariffSetArchive(tariff_id: number) {
        return this.httpClient.post<any>(`/api/admin/tariff/${tariff_id}/set-archive`, {});
    }

    getAdminIncidentsCategories() {
        return this.httpClient.get<any>('/api/admin/incidents/categories');
    }

    adminIncidentCategoryCreate(category: any) {
        return this.httpClient.post<any>('/api/admin/incidents/category/create', {name: category.name, kot_event: category.kot_event});
    }

    adminIncidentCategoryEdit(category: any) {
        return this.httpClient.put<any>(`/api/admin/incidents/category-${category.id}/edit`, {name: category.name, kot_event: category.kot_event});
    }

    adminIncidentCategoryDelete(category: any) {
        return this.httpClient.delete<any>(`/api/admin/incidents/category-${category.id}/delete`, {});
    }

    getAdminIncidentsRecipients() {
        return this.httpClient.get<any>('/api/admin/incidents/recipients');
    }

    adminIncidentRecipientCreate(recipient: any) {
        return this.httpClient.post<any>('/api/admin/incidents/recipient/create', {name: recipient.name});
    }

    adminIncidentRecipientEdit(recipient: any) {
        return this.httpClient.put<any>(`/api/admin/incidents/recipient-${recipient.id}/edit`, {name: recipient.name});
    }

    adminIncidentRecipientDelete(recipient_id: number) {
        return this.httpClient.delete<any>(`/api/admin/incidents/recipient-${recipient_id}/delete`, {});
    }

    adminIncidentRecipientUserDelete(recipient_id: number, user_id: number) {
        return this.httpClient.delete<any>(`/api/admin/incidents/recipient-${recipient_id}/user-${user_id}/delete`);
    }
}
