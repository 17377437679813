import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
// import * as Echo from 'laravel-echo';
import Echo from 'laravel-echo';
import * as io from 'socket.io-client';
import { Channel, PresenceChannel } from 'laravel-echo/src/channel/index';

declare const config: any;

@Injectable()
export class EchoService {
    private echo: Echo;

    constructor(private _auth: AuthService) {
        window['io'] = io;

        this.echo = window['Echo'] = new Echo({
            broadcaster: 'pusher',
            key: '17c11b02af105d68b61c',
            wsHost: window.location.hostname,
            wsPort: 6001,
            disableStats: true,
            forceTLS: false,
            cluster: 'ap2',
            encrypted: true,
            auth: {headers: {'Authorization': `${this._auth.hasToken() ? 'Bearer ' + this._auth.token.access_token : ''}`}}
        });
    }

    private(channel: string): Channel {
        return this.echo.private(channel);
    }

    channel(channel: string): Channel {
        return this.echo.channel(channel);
    }

    join(channel: string): PresenceChannel {
        return this.echo.join(channel);
    }

    leave(channel: string): void {
        this.echo.leave(channel);
    }
}
