import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { Helpers } from './helpers';
import { AuthService } from './services/auth.service';
import { Title } from '@angular/platform-browser';
import {I18nService} from './services/i18n.service';
import {registerLocaleData} from '@angular/common';
import localeEn from '@angular/common/locales/en';
import localeRu from '@angular/common/locales/ru';

declare const moment: any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
    // eslint-disable-next-line max-len
    globalBodyClass = 'm-page--loading-non-block m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-light m-aside-left--fixed m-aside-left--offcanvas m-aside-left--minimize m-brand--minimize m-footer--push m-aside--offcanvas-default';
    localesList = [
        { code: 'en', codeFull: 'en-US', codeData: 'localeEn', label: 'English' },
        { code: 'ru', codeFull: 'ru', codeData: 'localeRu', label: 'Русский' }
    ];
    constructor(private _router: Router, private _auth: AuthService, private titleService: Title, public _i18n: I18nService) {
    }

    ngOnInit() {
        this._auth.fetchUser();
        if (localStorage.getItem('currentLang') !== null) {
            this._i18n.locale =  localStorage.getItem('currentLang');
            if (this._i18n.locale !== 'ru') {
                const lang = this.localesList.find(o => o.code === this._i18n.locale);
                this._i18n.localeFull = lang.codeFull;
                registerLocaleData(lang.codeData);
                moment.locale(lang.code);
            }
        }
        // TO-DO Смена языка в БД (нужно сейчас или нет не понятно, при переводе БД будет нужно)
        // console.log(this._auth.user.locale);

        this._router.events.subscribe((route) => {
            if (route instanceof NavigationStart) {
                Helpers.setLoading(true);
                Helpers.bodyClass(this.globalBodyClass);
            }

            if (route instanceof NavigationEnd) {
                Helpers.setLoading(false);
            }
        });
    }
}
