import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { User } from '../_classes/user';

interface IAuthToken {
    access_token: string;
    expires_in: number;
    refresh_token: string;
    token_type: string;
}

@Injectable()
export class AuthService {
    remember = false;
    token: IAuthToken;
    user: User;

    constructor(private _http: HttpClient) {
        this.initToken();
        this.initUser();
    }

    isAuth() {
        return this.user && this.user.id;
    }

    fetchUser(callback: Function = null) {
        if (!this.hasToken()) {
            return;
        }

        this._http.post<any>('/api/user', {}).subscribe(res => {
            this.saveUserStorage(res);

            this.user = new User(res);

            if (callback) {
                callback(true);
            }
        });
    }

    initToken() {
        if (sessionStorage.getItem('token')) {
            this.token = JSON.parse(sessionStorage.getItem('token'));
        } else if (localStorage.getItem('token')) {
            this.token = JSON.parse(localStorage.getItem('token'));
        }
    }

    initUser() {
        if (!this.hasToken()) {
            return;
        }

        let user = null;

        if (sessionStorage.getItem('user')) {
            user = JSON.parse(sessionStorage.getItem('user'));
        } else if (localStorage.getItem('user')) {
            user = JSON.parse(localStorage.getItem('user'));
        }

        if (user && user.id) {
            this.user = new User(user);
        }
    }

    saveTokenStorage() {
        if (this.remember) {
            localStorage.setItem('token', JSON.stringify(this.token));
        } else {
            sessionStorage.setItem('token', JSON.stringify(this.token));
        }
    }

    saveUserStorage(user: any) {
        if (this.remember) {
            localStorage.setItem('user', JSON.stringify(user));
        } else {
            sessionStorage.setItem('user', JSON.stringify(user));
        }
    }

    hasToken(): boolean {
        if (!this.token || !this.token.access_token) {
            return false;
        }

        if (!this.token.expires_in || ((new Date()).getTime() > this.token.expires_in)) {
            this.logout();
            return false;
        }

        return true;
    }

    logout() {
        this.user = null;
        this.token = null;

        localStorage.removeItem('user');
        sessionStorage.removeItem('user');
        localStorage.removeItem('token');
        sessionStorage.removeItem('token');
    }
}
