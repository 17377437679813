import {Injectable} from '@angular/core';
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs/internal/Observable';
import {AuthService} from '../services/auth.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {
    constructor(private _auth: AuthService) {
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (request.url.indexOf('/api/') >= 0) {
            const headers = {};

            if (request.url.indexOf('/api/storage/image-upload') >= 0) {
                headers['Content-Type'] = 'multipart/form-data';
                headers['Accept'] = '*/*';
            } else {
                headers['Content-Type'] = 'application/json';
                headers['Accept'] = 'application/json';
            }

            headers['X-Requested-With'] = 'XMLHttpRequest';

            if (this._auth.hasToken()) {
                headers['Authorization'] = `Bearer ${this._auth.token.access_token}`;
            }

            const req = request.clone({setHeaders: headers});

            return next.handle(req);
        }

        return next.handle(request);
    }
}
