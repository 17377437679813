import { Injectable } from '@angular/core';

@Injectable()
export class ScriptLoaderService {
    private scripts: any = [];

    constructor() {}

    loadScripts(...scripts: string[]) {
        const promises: any[] = [];

        scripts.forEach((script) => promises.push(this.load(script)));

        return Promise.all(promises);
    }

    load(url: string) {
        return new Promise((resolve, reject) => {
            // resolve if already loaded
            if (this.scripts[url]) {
                resolve({script: url, loaded: true, status: 'Already Loaded'});
            } else {
                // load script
                const script = document.createElement('script');
                script.type = 'text/javascript';
                script.src = url;

                script.onload = () => {
                    this.scripts[url] = true;

                    resolve({script: url, loaded: true, status: 'Loaded'});
                };

                script.onerror = (error: any) => resolve({script: url, loaded: false, status: 'Loaded'});

                document.getElementsByTagName('body')[0].appendChild(script);
            }
        });
    }
}
