import { Injectable } from '@angular/core';

declare const toastr: any;

interface IToAstOptions {
    closeButton: boolean;
    debug: boolean;
    newestOnTop: boolean;
    progressBar: boolean;
    // tslint:disable-next-line:max-line-length
    positionClass: 'toast-top-right' | 'toast-bottom-right' | 'toast-bottom-left' | 'toast-top-left' | 'toast-top-full-width' | 'toast-bottom-full-width' | 'toast-top-center' | 'toast-bottom-center';
    preventDuplicates: boolean;
    onclick: Function | null;
    showDuration: number;
    hideDuration: number;
    timeOut: number;
    extendedTimeOut: number;
    showEasing: string;
    hideEasing: string;
    showMethod: string;
    hideMethod: string;
}

@Injectable()
export class ToAstService {
    default: IToAstOptions = {
        closeButton: false,
        debug: false,
        newestOnTop: false,
        progressBar: false,
        positionClass: 'toast-top-right',
        preventDuplicates: false,
        onclick: null,
        showDuration: 300,
        hideDuration: 1000,
        timeOut: 5000,
        extendedTimeOut: 1000,
        showEasing: 'swing',
        hideEasing: 'linear',
        showMethod: 'fadeIn',
        hideMethod: 'fadeOut'
    };

    constructor() { }

    success(title: string, message: string = null, options: IToAstOptions = this.default) {
        this.show('success', title, message, options);
    }

    info(title: string, message: string = null, options: IToAstOptions = this.default) {
        this.show('info', title, message, options);
    }

    warning(title: string, message: string = null, options: IToAstOptions = this.default) {
        this.show('warning', title, message, options);
    }

    error(title: string, message: string = null, options: IToAstOptions = this.default) {
        this.show('error', title, message, options);
    }

    show(type: 'success' | 'info' | 'warning' | 'error', title: string, message: string = null, options: IToAstOptions = this.default) {
        toastr.options = options;
        toastr[type](title, message);
    }
}
