import {Injectable} from '@angular/core';
import * as _ from 'lodash';

@Injectable()
export class I18nService {
    static locale: string;
    static localeFull: string;
    locale = 'ru';
    localeFull = 'ru';

    messages: any = {
        'ru': {
            'Карта устройств' : 'Карта устройств',
            'Вы действительно хотите удалить эту камеру?': 'Вы действительно хотите удалить эту камеру?',
            'У вас нет доступа к настройкам этой камеры' : 'У вас нет доступа к настройкам этой камеры',
            'Камера успешно удалена' : 'Камера успешно удалена',
            'Список камер' : 'Список камер',
            'Инциденты' : 'Инциденты',
            'Панель управления' : 'Панель управления',
            'Статистика' : 'Статистика',
            'Общая статистика' : 'Общая статистика',
            'Действия пользователей' : 'Действия пользователей',
            'Пользователи' : 'Пользователи',
            'Группы пользователей' : 'Группы пользователей',
            'Тарифы' : 'Тарифы',
            'Конфигурация' : 'Конфигурация',
            'Сервера' : 'Сервера',
            'Настройки инцидентов' : 'Настройки инцидентов',
            'Не удалось загрузить информацию и камере' : 'Не удалось загрузить информацию и камере',
            'Глобальные настройки доступа' : 'Глобальные настройки доступа',
            'Изменения успешно сохранены' : 'Изменения успешно сохранены',
            'Пользователь добавлен' : 'Пользователь добавлен',
            'Изменения сохранены' : 'Изменения сохранены',
            'Вы действительно хотите удалить права у пользователя?' : 'Вы действительно хотите удалить права у пользователя?',
            'Пользователь успешно создан' : 'Пользователь успешно создан',
            'Пользователь успешно удалён' : 'Пользователь успешно удалён',
            'Просматривать могут' : 'Просматривать могут',
            'Только выбранные' : 'Только выбранные',
            'Все авторизованные' : 'Все авторизованные',
            'Все пользователи' : 'Все пользователи',
            'К архиву имеют доступ' : 'К архиву имеют доступ',
            'Сохранить изменения' : 'Сохранить изменения',
            'Доступ у пользователей' : 'Доступ у пользователей',
            'Права доступа' : 'Права доступа',
            'Просмотр архива' : 'Просмотр архива',
            'Просмотр событий' : 'Просмотр событий',
            'Изменение конфигурации' : 'Изменение конфигурации',
            'Изменение позиции' : 'Изменение позиции',
            'Изменение прав доступа' : 'Изменение прав доступа',
            'Сейчас доступ к камере не имеет ни кто' : 'Сейчас доступ к камере не имеет ни кто',
            'Добавить пользователя' : 'Добавить пользователя',
            'Электронная почта' : 'Электронная почта',
            'Добавить' : 'Добавить',
            'Устройство успешно перезагружено' : 'Устройство успешно перезагружено',
            'Нет изменений для сохранения' : 'Нет изменений для сохранения',
            'Название камеры:' : 'Название камеры:',
            'Теги' : 'Теги',
            'Выберите теги из списка' : 'Выберите теги из списка',
            'Длительность записи:' : 'Длительность записи:',
            'Без записи архива' : 'Без записи архива',
            '1 день' : '1 день',
            '1 неделя' : '1 неделя',
            '2 недели' : '2 недели',
            '1 месяц' : '1 месяц',
            '2 месяца' : '2 месяца',
            '3 месяца' : '3 месяца',
            'Перезагрузить устройство' : 'Перезагрузить устройство',
            'Расположение:' : 'Расположение:',
            'Не выбрано' : 'Не выбрано',
            'Подъездная' : 'Подъездная',
            'Торцевая' : 'Торцевая',
            'Внутридомовая' : 'Внутридомовая',
            'Уличная' : 'Уличная',
            'Выберите расположение камеры' : 'Выберите расположение камеры',
            'Страна:' : 'Страна:',
            'Область:' : 'Область:',
            'Город:' : 'Город:',
            'Район:' : 'Район:',
            'Улица:' : 'Улица:',
            'Дом:' : 'Дом:',
            'Подъезд:' : 'Подъезд:',
            'Этаж:' : 'Этаж:',
            'Офис/Квартира:' : 'Офис/Квартира:',
            'Текущая позиция:' : 'Текущая позиция:',
            'У вас нет доступа к событиям этой камеры' : 'У вас нет доступа к событиям этой камеры',
            'Список событий' : 'Список событий',
            'Выберите диапазон:' : 'Выберите диапазон:',
            'Событие' : 'Событие',
            'Статус' : 'Статус',
            'Время' : 'Время',
            'Уведомлять по электронной почте' : 'Уведомлять по электронной почте',
            'Прямая трансляция' : 'Прямая трансляция',
            'Воспроизведение записи' : 'Воспроизведение записи',
            'Вернуться к прямой трансляции' : 'Вернуться к прямой трансляции',
            'Выбрать запись:' : 'Выбрать запись:',
            'Скорость воспроизведения' : 'Скорость воспроизведения',
            'Время записи (не учитывая скорость воспроизведения)' : 'Время записи (не учитывая скорость воспроизведения)',
            '2 минуты' : '2 минуты',
            '5 минут' : '5 минут',
            '10 минут' : '10 минут',
            '20 минут' : '20 минут',
            '30 минут' : '30 минут',
            '1 час' : '1 час',
            'Скачать' : 'Скачать',
            'Трансляция' : 'Трансляция',
            'События' : 'События',
            'Настройки' : 'Настройки',
            'Положение' : 'Положение',
            'Удалить' : 'Удалить',
            'Url потока успешно найден' : 'Url потока успешно найден',
            'Найти по серийному номеру' : 'Найти по серийному номеру',
            'Добавление камеры' : 'Добавление камеры',
            'Укажите серийный номер камеры' : 'Укажите серийный номер камеры',
            'Серийный номер' : 'Серийный номер',
            'Продолжить' : 'Продолжить',
            'Отменить' : 'Отменить',
            'Укажите логин и пароль от камеры' : 'Укажите логин и пароль от камеры',
            'Пароль' : 'Пароль',
            'Подтвердить' : 'Подтвердить',
            'Камера успешно создана' : 'Камера успешно создана',
            'добавить камеру' : 'добавить камеру',
            'Название камеры' : 'Название камеры',
            'URL потока' : 'URL потока',
            'Выберите камеру для начала' : 'Выберите камеру для начала',
            'Мультистрим' : 'Мультистрим',
            'Добавить камеру' : 'Добавить камеру',
            'На весь экран' : 'На весь экран',
            'Загрузка...' : 'Загрузка...',
            'Список пуст' : 'Список пуст',
            'Выберите камеры' : 'Выберите камеры',
            'для мультистрима' : 'для мультистрима',
            'Добавить камеру в мультистрим' : 'Добавить камеру в мультистрим',
            'Укажите название камеры:' : 'Укажите название камеры:',
            'Поиск...' : 'Поиск...',
            'Не удалось получить список камер...' : 'Не удалось получить список камер...',
            'Ничего не найдено' : 'Ничего не найдено',
            'Введите адрес' : 'Введите адрес',
            'Ваш регион:' : 'Ваш регион:',
            'Не удалось получить результат...' : 'Не удалось получить результат...',
            'Выберите регион' : 'Выберите регион',
            'добавить в мультистрим' : 'добавить в мультистрим',
            'просмотр событий' : 'просмотр событий',
            'редактировать конфигурацию' : 'редактировать конфигурацию',
            'редактировать положение' : 'редактировать положение',
            'редактировать права доступа' : 'редактировать права доступа',
            'Выберите дату' : 'Выберите дату',
            'Сообщить об инциденте' : 'Сообщить об инциденте',
            'Выберите категорию' : 'Выберите категорию',
            'Выберите дату и время начала инцидента' : 'Выберите дату и время начала инцидента',
            'Выберите дату и время окончания инцидента' : 'Выберите дату и время окончания инцидента',
            'Опишите инцидент' : 'Опишите инцидент',
            'Сообщить' : 'Сообщить',
            'Скорость потока' : 'Скорость потока',
            'Объем записаной информации' : 'Объем записаной информации',
            'Разрешение видео' : 'Разрешение видео',
            'Выбрать все категории' : 'Выбрать все категории',
            'Убрать все категории' : 'Убрать все категории',
            'Неправильный логин или пароль' : 'Неправильный логин или пароль',
            'Вход в систему' : 'Вход в систему',
            'Запомнить меня' : 'Запомнить меня',
            'Всё ещё нет аккаунта?' : 'Всё ещё нет аккаунта?',
            'Зарегистрироваться' : 'Зарегистрироваться',
            'Список инцидентов' : 'Список инцидентов',
            'Категория' : 'Категория',
            'Устройство' : 'Устройство',
            'Дата создания' : 'Дата создания',
            'В ожидании' : 'В ожидании',
            'В работе' : 'В работе',
            'Отменено' : 'Отменено',
            'Обработано' : 'Обработано',
            'Подробнее' : 'Подробнее',
            'Информация об инциденте' : 'Информация об инциденте',
            'Прислал' : 'Прислал',
            'Описание инцидента' : 'Описание инцидента',
            'Дата инцидента' : 'Дата инцидента',
            'Продолжительность' : 'Продолжительность',
            'Запись' : 'Запись',
            'Взять себе' : 'Взять себе',
            'Выполнить' : 'Выполнить',
            'Вернуть в работу' : 'Вернуть в работу',
            'Статистика устройств' : 'Статистика устройств',
            'События устройств' : 'События устройств',
            'Отключённые устройства' : 'Отключённые устройства',
            'Настройки пользователя' : 'Настройки пользователя',
            'Войти' : 'Войти',
            'Выйти' : 'Выйти',
            'Всего устройств в системе' : 'Всего устройств в системе',
            'Доступных мне' : 'Доступных мне',
            'Фильтровать устройства' : 'Фильтровать устройства',
            'Найти по названию' : 'Найти по названию',
            'Показывать только' : 'Показывать только',
            'Общедоступные' : 'Общедоступные',
            'Мои камеры' : 'Мои камеры',
            'Доступные мне камеры' : 'Доступные мне камеры',
            'Статус устройств' : 'Статус устройств',
            'Показывать все' : 'Показывать все',
            'Только активные' : 'Только активные',
            'Только отключённые' : 'Только отключённые',
            'Тип устройств' : 'Тип устройств',
            'Камеры' : 'Камеры',
            'Ваш профиль' : 'Ваш профиль',
            'Текущий баланс:' : 'Текущий баланс:',
            'Текущий тариф:' : 'Текущий тариф:',
            'Настройки профиля' : 'Настройки профиля',
            'Безопасность' : 'Безопасность',
            'Изменение пароля' : 'Изменение пароля',
            'Текущий пароль' : 'Текущий пароль',
            'Новый пароль' : 'Новый пароль',
            'Повторите новый пароль' : 'Повторите новый пароль',
            'Изменить пароль' : 'Изменить пароль',
            'Персональная информация' : 'Персональная информация',
            'Фамилия' : 'Фамилия',
            'Имя' : 'Имя',
            'Отчество' : 'Отчество',
            'Контактная информация' : 'Контактная информация',
            'Мобильный телефон' : 'Мобильный телефон',
            'Электронная почта для уведомлений' : 'Электронная почта для уведомлений',
            'Устройства' : 'Устройства',
            'Тип устройства' : 'Тип устройства',
            'Устройство отключилось' : 'Устройство отключилось',
            'Устройство не было включено' : 'Устройство не было включено',
            'Нет отключённых устройств' : 'Нет отключённых устройств',
            'Список групп пользователей' : 'Список групп пользователей',
            'Поиск' : 'Поиск',
            'Добавить группу' : 'Добавить группу',
            'Название' : 'Название',
            'Описание' : 'Описание',
            'Пользователей' : 'Пользователей',
            'Устройств' : 'Устройств',
            'Действия' : 'Действия',
            'изменить' : 'изменить',
            'Нельзя удалить группу с пользователями' : 'Нельзя удалить группу с пользователями',
            'Создание пользователя' : 'Создание пользователя',
            'Создание группы' : 'Создание группы',
            'Альтернативное название' : 'Альтернативное название',
            'Только английские символы. После создания нельзя будет менять.' : 'Только английские символы. После создания нельзя будет менять.',
            'Краткое описание' : 'Краткое описание',
            'Создать' : 'Создать',
            'Настройки группы' : 'Настройки группы',
            'Сохранить' : 'Сохранить',
            'Выберите сначала устройство' : 'Выберите сначала устройство',
            'Устройство добавлено' : 'Устройство добавлено',
            'Права доступа на устройство обновлены' : 'Права доступа на устройство обновлены',
            'Устройство удалено' : 'Устройство удалено',
            'Выберите сначала теги' : 'Выберите сначала теги',
            'Теги добавлены' : 'Теги добавлены',
            'Тег добавлен' : 'Тег добавлен',
            'Права доступа на тег обновлены' : 'Права доступа на тег обновлены',
            'Тег удалён' : 'Тег удалён',
            'Доступ к устройствам' : 'Доступ к устройствам',
            'Укажите название устройства:' : 'Укажите название устройства:',
            'Доступ к устройствам по тегу' : 'Доступ к устройствам по тегу',
            'Выберите теги:' : 'Выберите теги:',
            'Текущие права доступа группы' : 'Текущие права доступа группы',
            'У этой группы нет прав доступа' : 'У этой группы нет прав доступа',
            'Добавить права доступа группе' : 'Добавить права доступа группе',
            'Пользователь удалён' : 'Пользователь удалён',
            'Введите критерии поиска' : 'Введите критерии поиска',
            'Категории' : 'Категории',
            'Инцидентов' : 'Инцидентов',
            'Редактирование категории' : 'Редактирование категории',
            'Создание категории' : 'Создание категории',
            'Добавить получателя' : 'Добавить получателя',
            'Добавить дополнительные получатели' : 'Добавить дополнительные получатели',
            'Куда шлётся уведомление' : 'Куда шлётся уведомление',
            'По электронной почте' : 'По электронной почте',
            'По СМС' : 'По СМС',
            'Звонок [в разработке]' : 'Звонок [в разработке]',
            'Телефон' : 'Телефон',
            '(О каких категориях уведомлять)' : '(О каких категориях уведомлять)',
            'Редактировать дополнительные получатели' : 'Редактировать дополнительные получатели',
            'Тип' : 'Тип',
            'Значение' : 'Значение',
            'Дополнительных получателей ещё нет' : 'Дополнительных получателей ещё нет',
            'Выберите пользователя' : 'Выберите пользователя',
            'Выбран:' : 'Выбран:',
            'Подписки на категории' : 'Подписки на категории',
            'Редактирование получателя' : 'Редактирование получателя',
            'Пользователь' : 'Пользователь',
            'Пользователей не найдено' : 'Пользователей не найдено',
            'Устройство успешно перемещено' : 'Устройство успешно перемещено',
            'Все устройства успешно перемещены' : 'Все устройства успешно перемещены',
            'перенести' : 'перенести',
            'перенести все устройства' : 'перенести все устройства',
            'Название сервера' : 'Название сервера',
            'IP-Адрес сервера' : 'IP-Адрес сервера',
            'Порт сервера' : 'Порт сервера',
            'Логин' : 'Логин',
            'Сервер успешно удалён' : 'Сервер успешно удалён',
            'Устройств всего' : 'Устройств всего',
            'Устройств в сети' : 'Устройств в сети',
            'Клиентов' : 'Клиентов',
            'Использование ЦПУ' : 'Использование ЦПУ',
            'Входящий трафик' : 'Входящий трафик',
            'Сервер успешно создан' : 'Сервер успешно создан',
            'Список серверов' : 'Список серверов',
            'Добавить сервер' : 'Добавить сервер',
            'Сервер' : 'Сервер',
            'Онлайн' : 'Онлайн',
            'Создание сервера' : 'Создание сервера',
            'Настройки системы' : 'Настройки системы',
            'Основные' : 'Основные',
            'Внешний вид' : 'Внешний вид',
            'Сервисы' : 'Сервисы',
            'Регистрация' : 'Регистрация',
            'SMS-сообщения' : 'SMS-сообщения',
            'Настройки сайта' : 'Настройки сайта',
            'Название сайта' : 'Название сайта',
            'Используется в теге' : 'Используется в теге',
            'Введите название проекта' : 'Введите название проекта',
            'Копирайты' : 'Копирайты',
            'Можно использовать' : 'Можно использовать',
            'Логотипы' : 'Логотипы',
            'Текущий:' : 'Текущий:',
            'Новый:' : 'Новый:',
            'Выберите файл' : 'Выберите файл',
            'Большой логотип' : 'Большой логотип',
            'Маленький логотип' : 'Маленький логотип',
            'Положение на карте по умолчанию' : 'Положение на карте по умолчанию',
            'Настройки регистрации' : 'Настройки регистрации',
            'Регистрация аккаунта' : 'Регистрация аккаунта',
            'Включена ли регистрация пользователей' : 'Включена ли регистрация пользователей',
            'Драйвер почты' : 'Драйвер почты',
            'Драйвер, через который будут отправляться электронные письма' : 'Драйвер, через который будут отправляться электронные письма',
            'Общие настройки' : 'Общие настройки',
            'Имя отправителя' : 'Имя отправителя',
            'Имя, от кого отправлено письмо' : 'Имя, от кого отправлено письмо',
            'Имя пользователя для авторизации' : 'Имя пользователя для авторизации',
            'Пароль пользователя' : 'Пароль пользователя',
            'Пароль пользователя для авторизации' : 'Пароль пользователя для авторизации',
            'Настройки для SMTP' : 'Настройки для SMTP',
            'Хост' : 'Хост',
            'Хост SMTP сервера' : 'Хост SMTP сервера',
            'Порт' : 'Порт',
            'Порт SMTP сервера' : 'Порт SMTP сервера',
            'Стандартный порт для SSL шифрования:' : 'Стандартный порт для SSL шифрования:',
            'Стандартный порт для TLS шифрования:' : 'Стандартный порт для TLS шифрования:',
            'Стандартный порт без шифрования:' : 'Стандартный порт без шифрования:',
            'Метод шифрования' : 'Метод шифрования',
            'Метод шифрования SMTP сервера' : 'Метод шифрования SMTP сервера',
            'Нет' : 'Нет',
            'Настройки для Mailgun' : 'Настройки для Mailgun',
            'Домен' : 'Домен',
            'Секретный ключ' : 'Секретный ключ',
            'Настройки для SparkPost' : 'Настройки для SparkPost',
            'Настройки для Amazon SES' : 'Настройки для Amazon SES',
            'Публичный ключ' : 'Публичный ключ',
            'Регион' : 'Регион',
            'Драйвер SMS' : 'Драйвер SMS',
            'Драйвер, через который будут отправляться SMS сообщения' : 'Драйвер, через который будут отправляться SMS сообщения',
            'Настройки SMS.RU' : 'Настройки SMS.RU',
            'Секретный ключ (api_id)' : 'Секретный ключ (api_id)',
            'Новых устройств' : 'Новых устройств',
            'Добавлено новых устройств' : 'Добавлено новых устройств',
            'Посетителей' : 'Посетителей',
            'Количество авторизованных пользователей' : 'Количество авторизованных пользователей',
            'Новых пользователей' : 'Новых пользователей',
            'Присоединилось новых пользователей' : 'Присоединилось новых пользователей',
            'Новые пользователи' : 'Новые пользователи',
            'Профиль' : 'Профиль',
            'Фильтр по пользователю' : 'Фильтр по пользователю',
            'Фильтр для:' : 'Фильтр для:',
            'Действие' : 'Действие',
            'Дата и время' : 'Дата и время',
            'Действий пользователей за выбранный период не найдено' : 'Действий пользователей за выбранный период не найдено',
            'Тариф успешно создан' : 'Тариф успешно создан',
            'Тариф установлен по умолчанию' : 'Тариф установлен по умолчанию',
            'Тариф перенесён в архив' : 'Тариф перенесён в архив',
            'Тариф успешно изменён' : 'Тариф успешно изменён',
            'Группа успешно создана' : 'Группа успешно создана',
            'Список пользователей' : 'Список пользователей',
            'Имя пользователя' : 'Имя пользователя',
            'Группа' : 'Группа',
            'Группа удалена' : 'Группа удалена',
            'Группа добавлена' : 'Группа добавлена',
            'Выберите группу' : 'Выберите группу',
            'Профиль пользователя' : 'Профиль пользователя',
            'удалить' : 'удалить',
            'Введите свой пароль' : 'Введите свой пароль',
            'Создание получателя' : 'Создание получателя',
            'Управление пользователями' : 'Управление пользователями',
            'Дополнительные получатели' : 'Дополнительные получатели',
            'Управление тарифами' : 'Управление тарифами',
            'Расчётный период' : 'Расчётный период',
            'Сумма' : 'Сумма',
            'По умолчанию' : 'По умолчанию',
            'действующий' : 'действующий',
            'в архиве' : 'в архиве',
            'бесплатно' : 'бесплатно',
            'ежедневно' : 'ежедневно',
            'раз в неделю' : 'раз в неделю',
            'раз в месяц' : 'раз в месяц',
            'Тарифы ещё не созданы' : 'Тарифы ещё не созданы',
            'Создать тариф' : 'Создать тариф',
            'Списываемая сумма' : 'Списываемая сумма',
            'Опции тарифа' : 'Опции тарифа',
            'Максимальное кол-во устройств' : 'Максимальное кол-во устройств',
            '0 - для отключения лимита' : '0 - для отключения лимита',
            'Редактирование тарифа' : 'Редактирование тарифа ',
            'Описание тарифа' : 'Описание тарифа',
            'перенести в архив' : 'перенести в архив',
            'сделать тарифом по умолчанию' : 'сделать тарифом по умолчанию',
            'Забыли пароль?': 'Забыли пароль?',
            'Номер телефона': 'Номер телефона',
            'Подождите...': 'Подождите...'
        },
        'en' : {
            'Карта устройств' : 'Device map',
            'Вы действительно хотите удалить эту камеру?': 'Do you really want to delete this camera?',
            'У вас нет доступа к настройкам этой камеры' : 'You do not have access to the settings of this camera.',
            'Камера успешно удалена' : 'Camera removed successfully',
            'Список камер' : 'Camera list',
            'Инциденты' : 'Incidents',
            'Панель управления' : 'Control Panel',
            'Статистика' : 'Statistics',
            'Общая статистика' : 'General Statistics',
            'Действия пользователей' : 'User actions',
            'Пользователи' : 'Users',
            'Группы пользователей' : 'Group of users',
            'Тарифы' : 'Tariffs',
            'Конфигурация' : 'Configuration',
            'Сервера' : 'Media Servers',
            'Настройки инцидентов' : 'Incident Settings',
            'Не удалось загрузить информацию и камере' : 'Could not load information and camera',
            'Глобальные настройки доступа' : 'Global access settings',
            'Изменения успешно сохранены' : 'Changes saved successfully',
            'Пользователь добавлен' : 'User added',
            'Изменения сохранены' : 'Changes saved',
            'Вы действительно хотите удалить права у пользователя?' : 'Are you sure you want to remove user rights?',
            'Пользователь успешно создан' : 'User successfully created',
            'Пользователь успешно удалён' : 'User successfully deleted',
            'Просматривать могут' : 'Can view',
            'Только выбранные' : 'Only selected',
            'Все авторизованные' : 'All authorized',
            'Все пользователи' : 'All users',
            'К архиву имеют доступ' : 'Access to the archive',
            'Сохранить изменения' : 'Save changes',
            'Доступ у пользователей' : 'User access',
            'Права доступа' : 'Access rights',
            'Просмотр архива' : 'View archive',
            'Просмотр событий' : 'Event Viewer',
            'Изменение конфигурации' : 'Configuration change',
            'Изменение позиции' : 'Position change',
            'Изменение прав доступа' : 'Change permissions',
            'Сейчас доступ к камере не имеет ни кто' : 'Now no one has access to the camera',
            'Добавить пользователя' : 'Add user',
            'Электронная почта' : 'Email',
            'Добавить' : 'Add',
            'Устройство успешно перезагружено' : 'Device successfully restarted',
            'Нет изменений для сохранения' : 'No changes to save',
            'Название камеры:' : 'Camera title:',
            'Теги' : 'Tags',
            'Выберите теги из списка' : 'Select tags from the list',
            'Длительность записи:' : 'Duration of recording:',
            'Без записи архива' : 'Without archive recording',
            '1 день' : '1 day',
            '1 неделя' : '1 week',
            '2 недели' : '2 weeks',
            '1 месяц' : '1 month',
            '2 месяца' : '2 months',
            '3 месяца' : '3 months',
            'Перезагрузить устройство' : 'Reboot device',
            'Расположение:' : 'Location:',
            'Не выбрано' : 'Not chosen',
            'Подъездная' : 'Entrance',
            'Торцевая' : 'Outdoor',
            'Внутридомовая' : 'Intra-house',
            'Уличная' : 'Street',
            'Выберите расположение камеры' : 'Select camera location',
            'Страна:' : 'Country:',
            'Область:' : 'Region:',
            'Город:' : 'City:',
            'Район:' : 'District:',
            'Улица:' : 'Street:',
            'Дом:' : 'House:',
            'Подъезд:' : 'Entrance:',
            'Этаж:' : 'Floor:',
            'Офис/Квартира:' : 'Office / Apartment:',
            'Текущая позиция:' : 'Current position:',
            'У вас нет доступа к событиям этой камеры' : 'You do not have access to the events of this camera',
            'Список событий' : 'List of events',
            'Выберите диапазон:' : 'Select range:',
            'Событие' : 'Event',
            'Статус' : 'Status',
            'Время' : 'Time',
            'Уведомлять по электронной почте' : 'Notify by email',
            'Прямая трансляция' : 'Live broadcast',
            'Воспроизведение записи' : 'Playback Recording',
            'Вернуться к прямой трансляции' : 'Return to live broadcast',
            'Выбрать запись:' : 'Select Record:',
            'Скорость воспроизведения' : 'Playback speed',
            'Время записи (не учитывая скорость воспроизведения)' : 'Recording time (not including playback speed)',
            '2 минуты' : '2 minutes',
            '5 минут' : '5 minutes',
            '10 минут' : '10 minutes',
            '20 минут' : '20 minutes',
            '30 минут' : '30 minutes',
            '1 час' : '1 hour',
            'Скачать' : 'Download',
            'Трансляция' : 'Broadcast',
            'События' : 'Events',
            'Настройки' : 'Settings',
            'Положение' : 'Position',
            'Удалить' : 'Delete',
            'Url потока успешно найден' : 'Stream URL successfully found',
            'Найти по серийному номеру' : 'Find by serial number',
            'Добавление камеры' : 'Add camera',
            'Укажите серийный номер камеры' : 'Specify the serial number of the camera',
            'Серийный номер' : 'Serial number',
            'Продолжить' : 'Next',
            'Отменить' : 'Cancel',
            'Укажите логин и пароль от камеры' : 'Enter your username and password from the camera',
            'Пароль' : 'Password',
            'Подтвердить' : 'Confirm',
            'Камера успешно создана' : 'Camera successfully createdа',
            'добавить камеру' : 'add camera',
            'Название камеры' : 'Camera title',
            'URL потока' : 'Stream URL',
            'Выберите камеру для начала' : 'Select a camera to start.',
            'Мультистрим' : 'Multistream',
            'Добавить камеру' : 'Add camera',
            'На весь экран' : 'Full screen',
            'Загрузка...' : 'Loading...',
            'Список пуст' : 'List is empty',
            'Выберите камеры' : 'Select cameras',
            'для мультистрима' : 'for multistream',
            'Добавить камеру в мультистрим' : 'Add camera to multi-stream',
            'Укажите название камеры:' : 'Specify the name of the camera:',
            'Поиск...' : 'Search...',
            'Не удалось получить список камер...' : 'Could not get camera list...',
            'Ничего не найдено' : 'Nothing found',
            'Введите адрес' : 'Enter address',
            'Ваш регион:' : 'Your region:',
            'Не удалось получить результат...' : 'Could not get resultsт...',
            'Выберите регион' : 'Choose region',
            'добавить в мультистрим' : 'add to multistream',
            'просмотр событий' : 'view event',
            'редактировать конфигурацию' : 'edit configuration',
            'редактировать положение' : 'edit position',
            'редактировать права доступа' : 'edit access rights',
            'Выберите дату' : 'Choose a date',
            'Сообщить об инциденте' : 'Report an incident',
            'Выберите категорию' : 'Select a category',
            'Выберите дату и время начала инцидента' : 'Select the date and time of the incident.',
            'Выберите дату и время окончания инцидента' : 'Select the date and time of the end of the incident.',
            'Опишите инцидент' : 'Describe the incident',
            'Сообщить' : 'Report',
            'Скорость потока' : 'Flow rate',
            'Объем записаной информации' : 'Volume of recorded information',
            'Разрешение видео' : 'Video resolution',
            'Выбрать все категории' : 'Select all categories',
            'Убрать все категории' : 'Remove all categories',
            'Неправильный логин или пароль' : 'Incorrect login or password',
            'Вход в систему' : 'Log in system',
            'Запомнить меня' : 'Remember me',
            'Всё ещё нет аккаунта?' : 'Still no account?',
            'Зарегистрироваться' : 'To register',
            'Список инцидентов' : 'List of incidents',
            'Категория' : 'Category',
            'Устройство' : 'Device',
            'Дата создания' : 'Date of creation',
            'В ожидании' : 'Pending',
            'В работе' : 'In work',
            'Отменено' : 'Canceled',
            'Обработано' : 'Processed',
            'Подробнее' : 'Read more',
            'Информация об инциденте' : 'Incident information',
            'Прислал' : 'Sent by',
            'Описание инцидента' : 'Incident Description',
            'Дата инцидента' : 'Incident Date',
            'Продолжительность' : 'Duration',
            'Запись' : 'Record',
            'Взять себе' : 'Take yourself',
            'Выполнить' : 'Complete',
            'Вернуть в работу' : 'Return to work',
            'Статистика устройств' : 'Device statistics',
            'События устройств' : 'Device events',
            'Отключённые устройства' : 'Disconnected devices',
            'Настройки пользователя' : 'User Settings',
            'Войти' : 'Login',
            'Выйти' : 'Exit',
            'Всего устройств в системе' : 'Total devices in the system',
            'Доступных мне' : 'Available to me',
            'Фильтровать устройства' : 'Filter devices',
            'Найти по названию' : 'Find by name',
            'Показывать только' : 'Show only',
            'Общедоступные' : 'Publicly available',
            'Мои камеры' : 'My cameras',
            'Доступные мне камеры' : 'Cameras available to me',
            'Статус устройств' : 'Device Status',
            'Показывать все' : 'Show all',
            'Только активные' : 'Active only',
            'Только отключённые' : 'Disabled only',
            'Тип устройств' : 'Device type',
            'Камеры' : 'Cameras',
            'Ваш профиль' : 'Your profile',
            'Текущий баланс:' : 'Current balance:',
            'Текущий тариф:' : 'Current fare:',
            'Настройки профиля' : 'Profile Settings',
            'Безопасность' : 'Security',
            'Изменение пароля' : 'change Password',
            'Текущий пароль' : 'Current password',
            'Новый пароль' : 'New password',
            'Повторите новый пароль' : 'Repeat new password',
            'Изменить пароль' : 'Change Password',
            'Персональная информация' : 'Personal information',
            'Фамилия' : 'Surname',
            'Имя' : 'Name',
            'Отчество' : 'Middle name',
            'Контактная информация' : 'Contact Information',
            'Мобильный телефон' : 'Mobile phone',
            'Электронная почта для уведомлений' : 'Email for notifications',
            'Устройства' : 'Devices',
            'Тип устройства' : 'Device type',
            'Устройство отключилось' : 'Device has disconnected',
            'Устройство не было включено' : 'Device was not turned on',
            'Нет отключённых устройств' : 'No disabled devices',
            'Список групп пользователей' : 'List of user groups',
            'Поиск' : 'Search',
            'Добавить группу' : 'Add group',
            'Название' : 'Title',
            'Описание' : 'Description',
            'Пользователей' : 'Users',
            'Устройств' : 'Devices',
            'Действия' : 'Actions',
            'изменить' : 'change',
            'Нельзя удалить группу с пользователями' : 'You can not delete a group with users',
            'Создание пользователя' : 'User creation',
            'Создание группы' : 'Group creation',
            'Альтернативное название' : 'Alternative name',
            'Только английские символы. После создания нельзя будет менять.' : 'Only English characters. After creation it will be impossible to change.',
            'Краткое описание' : 'Short description',
            'Создать' : 'Create',
            'Настройки группы' : 'Group settings',
            'Сохранить' : 'Save',
            'Выберите сначала устройство' : 'Select device first',
            'Устройство добавлено' : 'Device added',
            'Права доступа на устройство обновлены' : 'Device Access Rights Updated',
            'Устройство удалено' : 'Device removed',
            'Выберите сначала теги' : 'Select tags first',
            'Теги добавлены' : 'Tags added',
            'Тег добавлен' : 'Tag added',
            'Права доступа на тег обновлены' : 'Tag access rights updated',
            'Тег удалён' : 'Tag removed',
            'Доступ к устройствам' : 'Access to devices',
            'Укажите название устройства:' : 'Enter the device name:',
            'Доступ к устройствам по тегу' : 'Access to devices by tag',
            'Выберите теги:' : 'Select tags:',
            'Текущие права доступа группы' : 'Current group permissions',
            'У этой группы нет прав доступа' : 'This group has no permissions',
            'Добавить права доступа группе' : 'Add permissions to group',
            'Пользователь удалён' : 'User deleted',
            'Введите критерии поиска' : 'Enter search criteria',
            'Категории' : 'Categories',
            'Инцидентов' : 'Incidents',
            'Редактирование категории' : 'Category editing',
            'Создание категории' : 'Create category',
            'Добавить получателя' : 'Add Recipient',
            'Добавить дополнительные получатели' : 'Add more recipients',
            'Куда шлётся уведомление' : 'Where is the notification sent?',
            'По электронной почте' : 'By email',
            'По СМС' : 'By SMS',
            'Звонок [в разработке]' : 'Call [in development]',
            'Телефон' : 'Phone',
            '(О каких категориях уведомлять)' : '(Which categories to notify)',
            'Редактировать дополнительные получатели' : 'Edit additional recipients',
            'Тип' : 'Type',
            'Значение' : 'Value',
            'Дополнительных получателей ещё нет' : 'No additional recipients yet',
            'Выберите пользователя' : 'Select a user',
            'Выбран:' : 'Selected:',
            'Подписки на категории' : 'Category Subscriptions',
            'Редактирование получателя' : 'Editing Recipient',
            'Пользователь' : 'User',
            'Пользователей не найдено' : 'No users found',
            'Устройство успешно перемещено' : 'Device successfully moved',
            'Все устройства успешно перемещены' : 'All devices moved successfully.',
            'перенести' : 'transfer',
            'перенести все устройства' : 'transfer all devices',
            'Название сервера' : 'Server name',
            'IP-Адрес сервера' : 'Server IP Address',
            'Порт сервера' : 'Server port',
            'Логин' : 'Login',
            'Сервер успешно удалён' : 'Server successfully removed',
            'Устройств всего' : 'Devices total',
            'Устройств в сети' : 'Devices online',
            'Клиентов' : 'Customers',
            'Использование ЦПУ' : 'CPU usage',
            'Входящий трафик' : 'Incoming traffic',
            'Сервер успешно создан' : 'Server successfully created',
            'Список серверов' : 'Server list',
            'Добавить сервер' : 'Add server',
            'Сервер' : 'Server',
            'Онлайн' : 'Online',
            'Создание сервера' : 'Server creation',
            'Настройки системы' : 'System settings',
            'Основные' : 'Main',
            'Внешний вид' : 'Appearance',
            'Сервисы' : 'Services',
            'Регистрация' : 'Registration',
            'SMS-сообщения' : 'SMS messages',
            'Настройки сайта' : 'Site settings',
            'Название сайта' : 'Name of the site',
            'Используется в теге' : 'Used in the tag',
            'Введите название проекта' : 'Enter project name',
            'Копирайты' : 'Copyright',
            'Можно использовать' : 'Can be used',
            'Логотипы' : 'Logos',
            'Текущий:' : 'Current:',
            'Новый:' : 'New:',
            'Выберите файл' : 'Select a file',
            'Большой логотип' : 'Large logo',
            'Маленький логотип' : 'Small logo',
            'Положение на карте по умолчанию' : 'Default map position',
            'Настройки регистрации' : 'Registration Settings',
            'Регистрация аккаунта' : 'Account Registration',
            'Включена ли регистрация пользователей' : 'Whether user registration is enabled',
            'Драйвер почты' : 'Mail driver',
            'Драйвер, через который будут отправляться электронные письма' : 'The driver through which emails will be sent',
            'Общие настройки' : 'General settings',
            'Имя отправителя' : 'Sender name',
            'Имя, от кого отправлено письмо' : 'Name from whom the letter is sent',
            'Имя пользователя для авторизации' : 'Login username',
            'Пароль пользователя' : 'User password',
            'Пароль пользователя для авторизации' : 'Authorization user password',
            'Настройки для SMTP' : 'Settings for SMTP',
            'Хост' : 'Host',
            'Хост SMTP сервера' : 'SMTP server host',
            'Порт' : 'Port',
            'Порт SMTP сервера' : 'SMTP server port',
            'Стандартный порт для SSL шифрования:' : 'Standard SSL encryption port:',
            'Стандартный порт для TLS шифрования:' : 'Standard port for TLS encryption:',
            'Стандартный порт без шифрования:' : 'Standard port without encryption:',
            'Метод шифрования' : 'Encryption method',
            'Метод шифрования SMTP сервера' : 'SMTP server encryption method',
            'Нет' : 'Not',
            'Настройки для Mailgun' : 'Settings for Mailgun',
            'Домен' : 'Domen',
            'Секретный ключ' : 'Secret key',
            'Настройки для SparkPost' : 'Settings for SparkPost',
            'Настройки для Amazon SES' : 'Settings for Amazon SES',
            'Публичный ключ' : 'Public key',
            'Регион' : 'Region',
            'Драйвер SMS' : 'Driver SMS',
            'Драйвер, через который будут отправляться SMS сообщения' : 'Driver through which SMS messages will be sent',
            'Настройки SMS.RU' : 'SMS.RU settings',
            'Секретный ключ (api_id)' : 'Secret key (api_id)',
            'Новых устройств' : 'New devices',
            'Добавлено новых устройств' : 'Added new devices',
            'Посетителей' : 'Visitors',
            'Количество авторизованных пользователей' : 'Number of authorized users',
            'Новых пользователей' : 'New users',
            'Присоединилось новых пользователей' : 'Joined new users',
            'Новые пользователи' : 'New users',
            'Профиль' : 'Profile',
            'Фильтр по пользователю' : 'Filter by user',
            'Фильтр для:' : 'Filter for:',
            'Действие' : 'Action',
            'Дата и время' : 'Date and time',
            'Действий пользователей за выбранный период не найдено' : 'No user actions were found for the selected period.',
            'Тариф успешно создан' : 'Tariff successfully created',
            'Тариф установлен по умолчанию' : 'Tariff set by default',
            'Тариф перенесён в архив' : 'Tariff moved to archive',
            'Тариф успешно изменён' : 'Rate successfully changed',
            'Группа успешно создана' : 'Group successfully created',
            'Список пользователей' : 'User list',
            'Имя пользователя' : 'Username',
            'Группа' : 'Group',
            'Группа удалена' : 'Group deleted',
            'Группа добавлена' : 'Group added',
            'Выберите группу' : 'Select group',
            'Профиль пользователя' : 'User Profile',
            'удалить' : 'Delete',
            'Введите свой пароль' : 'Enter your password',
            'Создание получателя' : 'Create Recipient',
            'Управление пользователями' : 'User management',
            'Дополнительные получатели' : 'Additional Recipients',
            'Управление тарифами' : 'Tariff Management',
            'Расчётный период' : 'Accounting period',
            'Сумма' : 'Amount',
            'По умолчанию' : 'Default',
            'действующий' : 'active',
            'в архиве' : 'in the archive',
            'бесплатно' : 'Free',
            'ежедневно' : 'daily',
            'раз в неделю' : 'once a week',
            'раз в месяц' : 'once a month',
            'Тарифы ещё не созданы' : 'Tariffs have not been created yet',
            'Создать тариф' : 'Create tariff',
            'Списываемая сумма' : 'Chargeable amount',
            'Опции тарифа' : 'Tariff options',
            'Максимальное кол-во устройств' : 'Maximum number of devices',
            '0 - для отключения лимита' : '0 - to disable the limit',
            'Редактирование тарифа' : 'Tariff Editing',
            'Описание тарифа' : 'Tariff Description',
            'перенести в архив' : 'transfer to archive',
            'сделать тарифом по умолчанию' : 'make the default rate',
            'Вы выбрали:' : 'You choosed:',
            'Группы' : 'Groups',
            'NVR Сервера' : 'NVR Servers',
            'Забыли пароль?': 'Forgot password?',
            'Номер телефона': 'Phone number',
            'Подождите...': 'Loading...',
            'Режим хранения': 'Storage mode',
            'Постоянная запись': 'Permanent entry',
            'Запись по расписанию': 'Schedule Recording',
            'Запись по событию': 'Event recording',
            'Не записывать': 'Do not record',
            'минут': 'minutes',
            'день': 'day',
            'неделя': 'week',
            'недели': 'weeks',
            'месяц': 'month',
            'месяца': 'months',
            'Добавить поток': 'Add stream',
            'Метод добавления': 'Add Method',
            'Добавить тег': 'Add tag',
            'По протоколу Onvif': 'By Onvif protocol',
            'электронных письмах и подобных сообщениях': 'emails and similar messages',
            'Фавикон': 'Favicon',
            'Введите имя пользователя': 'Enter username',
            'Введите пароль пользователя': 'Enter user password',
            'Адрес сервера': 'Server address',
            'Использование диска': 'Disk usage',
            'Использование памяти': 'Memory usage',
            'Список Cloud API серверов': 'List of Cloud API servers',
            'Добавить Cloud API сервер': 'Add Cloud API server',
            'Добавить NVR сервер': 'Add NVR server',
            'Получатели': 'Recipients',
            'Количество пользователей, просматривающих поток': 'Number of users viewing the stream',
            'Настройка уведомлений': 'Notification settings',
            'ВСЕ': 'ALL',
            'ВКЛ': 'ON',
            'ВЫКЛ': 'OFF',
            'Настройка всех устройств': 'Setting up all devices',
            'ЦПУ': 'CPU',
            'Память': 'Memory',
            'Диск': 'Disk',
            'Баланс/Тариф': 'Balance/Tariff',
            'Баланс пользователя': 'User balance',
            'Бонусы пользователя': 'User bonuses',
            'Текущий тариф': 'Current tariff',
            'Введите свой e-mail или телефон для восстановления': 'Enter your recovery email or phone number',
            'Укажите сервер Onvif': 'Specify the Onvif server',
            'Создание NVR Сервера': 'Creating an NVR Server',
            'Создание Cloud API Сервера': 'Creating a Cloud API Server',
            'сек': 'sec',
            'Префикс': 'prefix',
            'Вы действительно хотите удалить пользователя?': 'Are you sure you want to delete the user?',
            'Да, удалить!': 'Yes, delete!',
            'Отмена': 'Cancel',
            'Вы действительно хотите удалить группу?': 'Are you sure you want to delete the group?',
            'Группа успешно удалена': 'Group deleted successfully',
            'Вы действительно хотите удалить NVR Сервер?': 'Are you sure you want to delete the NVR server?',
            'Связать с аналитикой': 'Link to analytics',
            'Тип аналитики': 'Analytics type'
        }
    };



    constructor() {
    }

    get(key: string, def: string = null) {
        if (this.messages[this.locale][key] === undefined) {
            return key;
        }

        return this.messages[this.locale][key];
    }
}
